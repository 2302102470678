var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","clipped-right":"","color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"align-center d-flex"},[_c('span',{staticClass:"logo-icon"},[_c('img',{attrs:{"src":require("../../../assets/images/StreamitLogo.svg")}})]),_c('span',{staticClass:"logo-text ml-2",class:("" + (_vm.showLogo ? '' : 'hidelogo'))},[_c('img',{staticClass:"mt-2",attrs:{"src":require("../../../assets/images/StreamitLogo.svg")}})])]),_c('div',{on:{"click":_vm.showhideLogo}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
          ? _vm.setSidebarDrawer(!_vm.Sidebar_drawer)
          : _vm.$emit('input', !_vm.value)}}})],1),(_vm.showSearch)?_c('div',{staticClass:"searchinput primary"},[[_c('v-text-field',{staticClass:"mt-3 mb-0",attrs:{"placeholder":"Search & hit enter","append-icon":"mdi-close"},on:{"click:append":_vm.searchbox}})]],2):_vm._e(),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.client_list_display,"label":_vm.clientSelection === '' ? 'Clients' : ''},on:{"change":_vm.select_client},model:{value:(_vm.clientSelection),callback:function ($$v) {_vm.clientSelection=$$v},expression:"clientSelection"}}),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"dark":"","icon":""}},on),[_c('v-badge',{attrs:{"color":"red","dot":""}},[_c('v-icon',[_vm._v("mdi-message")])],1)],1)]}}])},[_c('v-list',[_c('h4',{staticClass:"px-5 py-3 pt-2 font-weight-medium title"},[_vm._v("Notifications")]),_c('v-divider'),_vm._l((_vm.notifications),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.href}},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-center py-3"},[_c('div',{},[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","fab":"","small":"","dark":"","color":item.iconbg}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(item.icon))])],1)],1),_c('div',[_c('h4',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"text--secondary caption descpart d-block text-truncate"},[_vm._v(_vm._s(item.desc))]),_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.time))])])])])],1)})],2)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"dark":"","icon":""}},on),[_c('v-badge',{attrs:{"color":"red","dot":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',[_c('h4',{staticClass:"px-5 pt-2 py-3 font-weight-medium title"},[_vm._v("Messages")]),_c('v-divider'),_vm._l((_vm.messages),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.href}},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-center py-3"},[_c('div',{staticClass:"mr-3"},[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":item.avatarstatus,"dot":"","offset-x":"10","offset-y":"10"}},[_c('v-avatar',[_c('img',{attrs:{"src":'https://www.wrappixel.com/demos/admin-templates/materialpro-bootstrap-latest/material-pro/src/assets/images/users/' +
                        item.avatar +
                        '.jpg',"alt":item.title}})])],1)],1),_c('div',[_c('h4',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"text--secondary caption descpart d-block text-truncate"},[_vm._v(_vm._s(item.desc))]),_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.time))])])])])],1)})],2)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"dark":"","icon":""}},on),[_c('v-avatar',{attrs:{"size":"40"}},[_c('img',{attrs:{"src":"https://cdn.vuetifyjs.com/images/john.jpg","alt":"John"}})])],1)]}}])},[_c('v-list',_vm._l((_vm.userprofile),function(item,i){return _c('v-list-item',{key:i,on:{"click":item.cmd}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }