export default [
  { header: "Admin" },
  {
    group: "/admin",
    model: true,
    icon: "mdi-collage",
    title: "Admin",
    children: [
      {
        title: "Users",
        icon: "mdi-adjust",
        to: "users"
      },
      {
        title: "Groups",
        icon: "mdi-adjust",
        to: "groups"
      },
      {
        title: "Points",
        icon: "mdi-adjust",
        to: "points"
      },
      {
        title: "Location Setup",
        icon: "mid-adjust",
        to: "locations"
      },
      {
        title: "Client List",
        icon: "mid-adjust",
        to: "clients"
      },
      {
        title: "Point Manager",
        icon: "mid-adjust",
        to: "point_manager"
      }
    ]
  },
  { header: "Control Panels" },
  {
    group: "/games",
    model: true,
    icon: "mdi-collage",
    title: "Games",
    children: [
      {
        title: "AV Control Panel",
        icon: "mid-adjust",
        to: "av_control_panel"
      },
      {
        title: "Leaderboards",
        icon: "mdi-adjust",
        to: "leaderboards"
      },
      {
        title: "Visit To Winit",
        icon: "mdi-adjust",
        to: "visittowinit"
      },
      {
        title: "Easter Egg Hunt",
        icon: "mdi-adjust",
        to: "easteregghunt"
      },
      {
        title: "Cocktail Directory",
        icon: "mdi-adjust",
        to: "cocktaildirectory"
      },
      {
        title: "Quizzes",
        icon: "mdi-adjust",
        to: "quizgame"
      },
      {
        title: "Social Feed",
        icon: "mdi-adjust",
        to: "socialfeed"
      },
      {
        title: "Reactions",
        icon: "mdi-adjust",
        to: "reactions"
      }
    ]
  }
];
